header {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-flow: column;
  color: white;
  margin: 0 2em 0 2em;
}
header > h1 {
  font-size: 3.5rem;
}
header > h2 {
  font-size: 1.5em;
  line-height: 30px;
  text-align: left;
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  header > h1 {
    font-size: 4rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  header > h1 {
    font-size: 5rem;
  }
}
@media screen and (min-device-width: 1200px) {
  header > h1 {
    font-size: 10rem;
  }
}
