html {
  height: 100%;
}
body {
  background-color: rgb(38, 38, 38);
  font-family: "AkkuratPro", sans-serif;
  padding: 0;
  margin: 0;
  letter-spacing: 3px;
  height: 100%;
}
.App {
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
