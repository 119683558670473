#store-links-heading {
  color: white;
  padding: 10px 12vw 0 12vw;
}
#link-section {
  padding: 5em 0;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
}
.store-link {
  display: block;
  height: 6em;
}
#app-store {
  height: 10em;
}
