#history {
  background-color: white;
  display: grid;
  place-items: center;
  grid-template-columns: 0.5fr 0.5fr;
  padding: 2em 0;
}
#history > section {
  padding: 1.2em 2em 0 2em;
}
#app-name {
  font-weight: bold;
  font-size: 1.3em;
}
#history > img {
  height: 17em;
  -webkit-box-shadow: -13px 5px 15px 5px #000000;
  box-shadow: -13px 5px 15px 5px #000000;
  border-radius: 15px;
}
/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  #history > img {
    height: 22em;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  #history {
    font-size: 1.5em;
  }
}
@media screen and (min-device-width: 1200px) {
  #history {
    font-size: 2em;
  }
}
