#team-heading {
  text-align: center;
  margin: 10px 12vw 0 12vw;
}
#team-imgs {
  padding: 1em 0;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
}
.employee-item {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  text-align: center;
}
.employee-item > img {
  margin: 1em;
  height: 6.5em;
  border-radius: 50%;
}
#team {
  padding: 25px 0;
  color: white;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  #team > img {
    height: 15em;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  #team {
    font-size: 1.5em;
  }
}

@media screen and (min-device-width: 1200px) {
  #history {
    font-size: 2em;
  }
  #team-imgs {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .employee-item > img {
    height: 15em;
  }
}
