#decode-link-section {
  display: grid;
  place-items: center;
  background-color: white;
  padding: 6em 0;
}

#decode-link {
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  padding: 15px;
  border: 3px solid black;
}
