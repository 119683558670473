#loading-indicator {
  display: grid;
  height: 100%;
  padding: 2em;
  justify-items: center;
}
#droplet {
  position: relative;
  width: 100px;
  height: 100px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 75px;
  -webkit-border-bottom-right-radius: 75px;
  -webkit-border-bottom-left-radius: 75px;
  -webkit-transform: rotate(45deg);
  box-shadow: 1px 1px 7px #1a1a1a;
  animation: dropletFall 3s infinite;
}

/*---Keyframe animation*/
@keyframes dropletFall {
  0% {
    background-color: white;
    top: 0vh;
  }
  100% {
    background-color: red;
    top: 40vh;
  }
}
